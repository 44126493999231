import React from 'react';
import PageContentContainer from 'components/PageContentContainer';
import { Typography } from '@mui/material';

const NotFound = ({ staticContext = {} }) => {
  staticContext.status = 404;

  return (
    <PageContentContainer heading="404 Not Found" meta={{ description: '', noIndex: true }}>
      <Typography component="h2">Is there anybody out there?</Typography>
    </PageContentContainer>
  );
};

export default NotFound;
